<template>
  <div class="items">
    <v-card class="mb-2">
      <v-card-title>Master Item</v-card-title>

      <v-divider></v-divider>

      <v-expansion-panels>
        <v-expansion-panel>
          <v-expansion-panel-header> Filter </v-expansion-panel-header>

          <v-expansion-panel-content>
            <v-select
              v-model="filter.ready"
              :items="filter.readyList"
              class="mb-2"
              item-text="name"
              item-value="id"
              label="Tampilkan"
              hide-details="auto"
              outlined
            ></v-select>

            <v-select
              v-model="filter.taxType"
              :items="filter.taxTypes"
              item-text="name"
              item-value="id"
              label="Tipe Pajak"
              hide-details
              outlined
            ></v-select>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-card>

    <v-card
      v-if="!isSalesman"
      :loading="summary.loading"
      :color="summary.loading ? '' : '#15263b'"
      class="mb-2"
    >
      <v-card-title class="pb-1">Total Stock</v-card-title>

      <v-card-text>
        <h2 v-text="summary.total"></h2>
      </v-card-text>
    </v-card>

    <v-card class="mb-2">
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Search"
        filled
        hide-details
        single-line
        @input="getItems"
      ></v-text-field>

      <v-data-table
        :headers="headers"
        :items="items.data"
        :loading="items.loading"
        :options.sync="options"
        :server-items-length="items.count"
        class="elevation-1"
      >
        <template v-slot:[`item.actions`]="{ item }">
          <v-chip
            v-if="item.mb_flag !== ITEM_TYPES.SERVICE"
            :color="item.qty > 0 ? 'light-blue' : 'red'"
            class="mr-2 mb-2"
            small
            >QTY : {{ item.qty }} {{ item.units }}</v-chip
          >

          <v-btn color="light-blue" small @click="openItem(item.stock_id)">
            Details
          </v-btn>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import ITEM_TYPES from '@/constants/item-types'

export default {
  name: 'Items',

  data() {
    return {
      filter: {
        readyList: [
          { id: 0, name: 'Semua' },
          { id: 1, name: 'Ready' },
        ],
        ready: 1,
        taxTypes: [
          { id: 0, name: 'Semua' },
          { id: 1, name: 'NP' },
          { id: 2, name: 'KP' },
        ],
        taxType: 0,
      },
      headers: [
        { text: 'Kode Item', value: 'stock_id' },
        { text: 'Nama Item', value: 'description' },
        { sortable: false, text: '', value: 'actions' },
      ],
      items: {
        count: 0,
        data: [],
        loading: false,
      },
      options: {},
      search: '',
      summary: {
        loading: false,
        total: 0,
      },
    }
  },

  computed: {
    isSalesman() {
      return this.getSalesmanStatus()
    },

    ITEM_TYPES() {
      return ITEM_TYPES
    },
  },

  watch: {
    options: {
      handler() {
        this.getItems()
      },

      deep: true,
    },

    filter: {
      handler() {
        this.getItems()
      },

      deep: true,
    },
  },

  mounted() {
    if (!this.isSalesman) this.getSummary()
  },

  methods: {
    getSummary() {
      this.summary.loading = true

      this.$http
        .get('/inventory/items/summary')
        .then(({ data }) => {
          this.summary.total = data.summary.total
        })
        .catch((err) => {
          console.log(err)
        })
        .then(() => {
          this.summary.loading = false
        })
    },

    getItems() {
      const { sortBy, sortDesc, page, itemsPerPage } = this.options

      this.items.loading = true

      this.$http
        .get('/inventory/items', {
          params: {
            itemsPerPage: itemsPerPage,
            page: page,
            search: this.search,
            sortBy: sortBy[0] ? sortBy[0] : '',
            sortDesc: sortDesc[0] ? sortDesc[0] : '',
            readyOnly: this.filter.ready == 1 ? 1 : '',
            taxType: this.filter.taxType !== 0 ? this.filter.taxType : '',
          },
        })
        .then((result) => {
          this.items.count = result.data.count
          this.items.data = result.data.result
          this.items.loading = false
        })
    },

    openItem(stockId) {
      this.$router.push('/inventory/items/' + stockId)
    },
  },
}
</script>

<style lang="scss" scoped>
table td {
  white-space: nowrap;
  width: 1%;
}
</style>
